import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AntsrouteCommonModule, TerminologyPipe } from '@antsway/antsroute-common';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { TranslateModule } from '@ngx-translate/core';
import { BarRatingModule } from 'ngx-bar-rating';
import { CountdownModule } from 'ngx-countdown';
import { ContactComponent } from './components/contact/contact.component';
import { EditCustomFieldComponent } from './components/edit-custom-field/edit-custom-field.component';
import { HeaderComponent } from './components/header/header.component';
import { LanguageSelectComponent } from './components/language-select/language-select.component';
import { LocationFinderComponent } from './components/location-finder/location-finder.component';
import { MapComponent } from './components/map/map.component';
import { OrderInfosComponent } from './components/order-infos/order-infos.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { ScoreComponent } from './components/score/score.component';
import { TitlePipe } from './pipes/title.pipe';

@NgModule({
  declarations: [
    ContactComponent,
    MapComponent,
    HeaderComponent,
    OrderInfosComponent,
    LanguageSelectComponent,
    TitlePipe,
    LocationFinderComponent,
    PhoneInputComponent,
    EditCustomFieldComponent,
    ScoreComponent
  ],
  imports: [
    // Modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FlexLayoutModule,
    LeafletModule,
    CountdownModule,
    BarRatingModule,
    AntsrouteCommonModule,

    // Material
    MatCardModule,
    MatButtonModule,
    MatDividerModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatSnackBarModule,
    MatSelectModule,
    MatTooltipModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatDatepickerModule
  ],
  exports: [
    // Components
    ContactComponent,
    MapComponent,
    HeaderComponent,
    OrderInfosComponent,
    LanguageSelectComponent,
    ScoreComponent,

    // Pipes
    TitlePipe,

    // Modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FlexLayoutModule,
    CountdownModule,
    BarRatingModule,
    AntsrouteCommonModule,

    // Material
    MatCardModule,
    MatButtonModule,
    MatDividerModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatSelectModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [TerminologyPipe]
})
export class SharedModule {}
